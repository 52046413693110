import RecipeForm from '../../containers/Form';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from '../../theme';
import { ResetCSS } from '../../assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../../containers/NavbarAlt';
import GlobalStyle, { AppWrapper, ContentWrapper } from '../../containers/global.style';
import SEO from '../../components/seo';
import '../../utils/i18n';
import { ToastProvider } from 'react-toast-notifications';

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <SEO title='Recipe Database' />
      <Modal />
      <ResetCSS />
      <GlobalStyle />
      <AppWrapper>
        <Sticky top={1} innerZ={9999} activeClass='sticky-active'>
          <Navbar />
        </Sticky>
        <ContentWrapper>
          <ToastProvider placement='bottom-center' autoDismiss={true}>
            <RecipeForm stailey={false} phil={false} />
          </ToastProvider>
        </ContentWrapper>
      </AppWrapper>
    </ThemeProvider>
  );
};
